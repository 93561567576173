import { AutocompleteSource } from "@algolia/autocomplete-js";
import { BaseItem } from "@algolia/autocomplete-core";
import { LiteClient } from "algoliasearch/lite";
import React from "react";

export type AlgoliaContext = {
  nbProducts: number | undefined;
}

export type SetContext = (context: AlgoliaContext) => void;

export const ALGOLIA_PRODUCTS_RESULTS_NUMBER = 6;

export default abstract class AutocompleteConfig<T extends BaseItem> {
  indexName: string;
  searchAppId?: string;
  searchPublicApiKey?: string;
  numResults: number;

  protected constructor(indexName: string, searchAppId?: string, searchPublicApiKey?: string) {
    this.indexName = indexName;
    this.searchAppId = searchAppId;
    this.searchPublicApiKey = searchPublicApiKey
    this.numResults = ALGOLIA_PRODUCTS_RESULTS_NUMBER;
  }

  abstract readonly emptyQueryCollectionId: string;
  abstract readonly emptyQueryCollectionTitle: string;
  abstract readonly queryCollectionId: string;

  abstract filterString(): string;

  abstract emptyQueryResults(): AutocompleteSource<T> | boolean | undefined;

  abstract queryResults(searchClient: LiteClient, setContext: SetContext): AutocompleteSource<T> | boolean | undefined;

  abstract searchResultsPath(query: string): string | undefined;

  abstract itemComponent(item: T, index: number): React.JSX.Element;

  abstract topSearchResult(): React.JSX.Element[];

  abstract topRecommendationResult(): React.JSX.Element[];

  abstract expandResults(event: React.MouseEvent, newResultCount: number): void;

  abstract storeStyleText(): React.JSX.Element;
}
